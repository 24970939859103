// ** React Imports
import { useState, createContext } from 'react'

// ** Intl Provider Import
import { IntlProvider } from 'react-intl'

// ** Core Language Data
import messagesEn from '../../assets/data/locales/en.json'
import messagesDe from '../../assets/data/locales/de.json'
import messagesFr from '../../assets/data/locales/fr.json'
import messagesPt from '../../assets/data/locales/pt.json'
import messagesEs from '../../assets/data/locales/es.json'
import messagesNe from '../../assets/data/locales/ne.json'
import messagesCn from '../../assets/data/locales/cn.json'


// ** User Language Data
import userMessagesEn from '../../@core/assets/data/locales/en.json'
import userMessagesDe from '../../@core/assets/data/locales/de.json'
import userMessagesFr from '../../@core/assets/data/locales/fr.json'
import userMessagesPt from '../../@core/assets/data/locales/pt.json'
import userMessagesEs from '../../@core/assets/data/locales/es.json'
import userMessagesNe from '../../@core/assets/data/locales/ne.json'
import userMessagesCn from '../../@core/assets/data/locales/cn.json'

// ** Menu msg obj
const menuMessages = {
  en: { ...messagesEn, ...userMessagesEn },
  de: { ...messagesDe, ...userMessagesDe },
  fr: { ...messagesFr, ...userMessagesFr },
  pt: { ...messagesPt, ...userMessagesPt },
  es: { ...messagesEs, ...userMessagesEs },
  ne: { ...messagesNe, ...userMessagesNe },
  cn: { ...messagesCn, ...userMessagesCn }
}

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({ children }) => {
  // ** States
  const [locale, setLocale] = useState('en')
  const [messages, setMessages] = useState(menuMessages['en'])

  // ** Switches Language
  const switchLanguage = lang => {
    setLocale(lang)
    setMessages(menuMessages[lang])
  }

  return (
    <Context.Provider value={{ locale, switchLanguage }}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale='en'>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export { IntlProviderWrapper, Context as IntlContext }
