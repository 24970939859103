import React from 'react'
import { Box, Text, Button } from '@blockstack/ui'
import { authenticate } from '../auth'

export const Signin = () => {
  return (
    <div style={{ backgroundImage: 'url("cover-art.png")', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', display: 'flex', alignItems: 'center' }}>   
  <br/>   <br/>   <br/>   <br/>   <br/>   <br/> 
   <Box marginLeft={'100px'} marginTop={'200px'} marginBottom={'50px'} maxWidth="600px" height="800px" textAlign="center">
      <Box maxWidth="600px" maxHeight="600px" mx="auto" mt={[6, '10px']}>
      <div style={{ backgroundColor: 'white',  marginLeft: '10px', marginRight: '10px' }}>   
      <br/>
        <Text color="black" fontWeight="1200" fontSize={['72px', '108px']} lineHeight={1} display="block">
          Welcome to Bitfari!
        </Text>
        <Text color="black" fontWeight="300" fontSize={['16px', '36px']} lineHeight={1} display="block">
          First Advertising DAO of America
        </Text>
        <br/>
        <Text color="black" fontWeight="300" marginLeft={'50px'}  marginRight={'50px'} fontSize={['184px', '18px']} lineHeight={1} display="block">
          Mainnet Opens Soon! Browse screen registration,
          <br/> ad booking, discounts, dashboards, and more! Three apps already online!
        </Text>
        
        <Box mt={[36, '36px']}>
          <Button onClick={() => authenticate()}>Connect your Stacks Wallet to Enter</Button>
        </Box>

        <Text color="black" fontWeight="300" marginLeft={'50px'} marginRight={'50px'} marginTop={'50px'}  marginBottom={'50px'} fontSize={['16px', '16px']} lineHeight={1} display="block">
          If you don't have one, you can <a style={{color: "blue"}} href="https://www.hiro.so/wallet/install-web">get a Stacks wallet (free and easy to use)</a>
        </Text>
        <Text color="black" fontWeight="300" marginLeft={'50px'} marginRight={'50px'} marginTop={'50px'}  marginBottom={'50px'} fontSize={['16px', '16px']} lineHeight={1} display="block">
          Buy an NFT from Antonyo Marest <a style={{color: "blue"}} href="https://antonyomarest.es/" target="_blank">Today's Featured Artist!</a>
        </Text>
        <br/>
        </div>
       </Box>
    </Box>
    <br/>   <br/>   <br/>   <br/>   <br/>   <br/> 
    </div>
  )
}
