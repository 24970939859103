import { Box, Mail, Map, Server,  MessageSquare, CheckSquare, Calendar } from 'react-feather'

export default [
  {
    id: 'apps',
    title: 'Apps',
    icon: <Box />,
    children: [
      {
        id: 'digital-land',
        title: 'Digital Land Market',
        icon: <Map />,
        navLink: '/apps/digital-land'
      },
      {
        id: 'status',
        title: 'Network Status',
        icon: <Server />,
        navLink: '/apps/network-status'
      },
    {
    id: 'soon',
    title: 'Coming Soon...',
    icon: <Box />,
    children: [

      {
        id: 'focus',
        title: 'Focus',
        icon: <CheckSquare />,
        navLink: '/apps/focus'
      },
      {
        id: 'book',
        title: 'Book Ads',
        icon: <Calendar />,
        navLink: '/apps/book'
      },
    {
        id: 'tellfari',
        title: 'Tellfari',
        icon: <Mail />,
        navLink: '/apps/tellfari'
      },
      {
        id: 'instafriend',
        title: 'InstaFriend',
        icon: <MessageSquare />,
        navLink: '/apps/instafriend'
      }
     ]
  }
    ]
  }
]
